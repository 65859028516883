import React from "react";

function Footer() {
    return (
        <footer id="footer">
            <p className="copyright">&copy; Untitled. All rights reserved. Demo Images: <a href="https://unsplash.com">Unsplash</a>. Design: <a href="https://html5up.net">HTML5 UP</a>.</p>
        </footer>
    );
}

export default Footer;